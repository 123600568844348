import React from 'react';
import SEO from '../components/seo';
import styled from 'styled-components';
import Layout from '../components/layout';
import TownEntranceImage from '../images/town-entrance.jpeg';
import DayDriveImage from '../images/bales-of-hay.jpeg';
import { subheadingMixin } from '../theme/mixins';
import { PageBanner } from '../components/page-banner';

const Subheading = styled.h2`
  ${subheadingMixin}
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const MiddleContentSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin: 64px 100px;
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    .text-section {
      order: 2;
    }
    &.top-content {
      margin: 64px 30px 32px 30px;
    }
    &.bottom-content {
      margin: 32px 30px 64px 30px;
    }
  }

  .picture-section {
    border-radius: 16px;
    height: 400px;
    max-width: 100%;
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    @media only screen and (max-width: 600px) {
      height: 300px;
    }
  }
  .text-section {
    display: flex;
    flex-direction: column;
    ul {
      margin: 16px 0;
      list-style-type: circle;
      li {
        margin-left: 32px;
      }
    }
  }
`;

const ThingsInPeterborough = () => {
  return (
    <Layout useContentWrapper={false}>
      <SEO title="Things in Peterborough" />
      <PageBanner title="Things to do in Peterborough" />

      <MiddleContentSection className="top-content">
        <div className="text-section">
          <Subheading>In the town</Subheading>
          <p>
            If you're looking for something to do close-by, Peterborough has a rich and interesting
            railway history, among other things:
          </p>
          <ul>
            <li>
              Steamtown Hertiage Rail Centre, Day Tours & Light & Sound Night Show - Booking
              Essential at the Visitors Information Centre.
            </li>
            <li>Y Class Locomotive in the Main Street.</li>
            <li>Historic Gold Battery</li>
            <li>The Burg – Display of Petersburg.</li>
            <li>Motorbike Museum – Please ring Ian for times.</li>
            <li>Peterborough Printing Works.</li>
          </ul>
        </div>
        <div>
          <img src={TownEntranceImage} alt="" className="picture-section" />
        </div>
      </MiddleContentSection>

      <MiddleContentSection className="bottom-content">
        <div>
          <img alt="Day Drives" src={DayDriveImage} className="picture-section" />
        </div>
        <div className="text-section">
          <Subheading>Day Drives</Subheading>
          <p>
            If you are keen to take your time and explore the surrounding areas. Take the day and go
            out for a drive to one of the following places:
          </p>
          <ul>
            <li>Orroroo</li>
            <li>Magnetic Hill</li>
            <li>Terowie</li>
            <li>Hallett</li>
            <li>Mt Dare</li>
            <li>Yongla</li>
            <li>Appilla Springs</li>
            <li>Black Rock</li>
            <li>Dawson</li>
          </ul>
        </div>
      </MiddleContentSection>
    </Layout>
  );
};

export default ThingsInPeterborough;
