import React from 'react';
import styled from 'styled-components';
import GrassAreaImage from '../images/grass-area.jpg';
import { headingMixin, paragraphMixin } from '../theme/mixins';

const BannerContainer = styled.div`
  width: 100%;
  height: 260px;
  background: #6e00fb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  overflow: hidden;

  &::before {
    content: '';
    background: url(${GrassAreaImage});
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);
    transform: scale(1.1);
  }

  .text-container {
    position: relative;
    margin: 0 64px;
    z-index: 2;
    text-align: center;
    .banner-title {
      ${headingMixin}
    }
    .banner-subtitle {
      ${paragraphMixin}
    }
  }
`;

export function PageBanner({ title = '', subtitle = null }) {
  return (
    <BannerContainer>
      <div className="text-container">
        <p className="banner-title">{title}</p>
        {subtitle && <p className="banner-subtitle">{subtitle}</p>}
      </div>
    </BannerContainer>
  );
}
